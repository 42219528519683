import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

import Home from './index/index';
import PrivacyPolicy from './auxiliary/privacy_policy';
import TermsOfService from './auxiliary/terms';
import Contact from './auxiliary/contact';
import Changelog from './auxiliary/changelog';
import ComingSoon from './auxiliary/comingsoon';
import Layout from "./Layout";
import Unsubscribe from './email/unsubscribe';

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react"

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  return (
    <Router>
      <Analytics />
      <SpeedInsights />
      <div className="App">
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/waitlist" element={<Waitlist />} />
          <Route path="/first_class" element={<FirstClass />} />
          <Route path="/features" element={<Features />} />
          <Route path="/security" element={<Security />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact_us" element={<Contact />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/accessibility" element={<Accessibility />} />*/}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
          <Route path="/contact" element={<Contact />} />
          <Route path="/changelog" element={<Changelog />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          {/* <Route path="/test" element={<TestComponent />} /> */}
          {/* <Route path="/trip" element={<Trip />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
