import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './comingsoon.module.css';
import { MoveUpRight, Smartphone, Globe, ArrowRight, Check } from 'lucide-react';
import Header from './header';
import Footer from '../components/footer';
import { DarkModeContext } from '../DarkModeProvider';
import ThreeJSBalloons from './balloons';

const ComingSoon = () => {
  const { isDarkMode } = useContext(DarkModeContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [honeypot, setHoneypot] = useState(''); // Honeypot field to catch bots
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      setIsMobile(newIsMobile);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handleHoneypotChange = (e) => {
    setHoneypot(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // If honeypot field is filled, show fake success but don't submit (bot detected)
    if (honeypot) {
      setIsSubmitting(true);
      
      // Simulate loading time for bots
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      setTriggerAnimation(true);
      setSubmitted(true);
      setEmail('');
      
      // Keep balloons visible until page reload - removed timeout that resets triggerAnimation
      
      setIsSubmitting(false);
      return;
    }
    
    if (!email) {
      setError('Please enter your email address');
      return;
    }
    
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    setIsSubmitting(true);
    
    // Record start time
    const startTime = Date.now();
    
    try {
      // Update to use GET request with query parameters instead of POST with JSON body
      const response = await fetch(`https://us-central1-vacay-project.cloudfunctions.net/add_user_to_email_list?email=${encodeURIComponent(email)}`, {
        method: 'GET',
      });
      
      // Check if the response is JSON before trying to parse it
      const contentType = response.headers.get('content-type');
      let data;
      
      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
      } else {
        // Handle non-JSON response
        data = { success: response.ok, message: await response.text() };
      }
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to submit email');
      }
      
      console.log('Email submitted:', email);
      setTriggerAnimation(true);
      
      // Calculate elapsed time and wait if needed to ensure minimum 2 seconds of loading
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, 2000 - elapsedTime);
      
      if (remainingTime > 0) {
        await new Promise(resolve => setTimeout(resolve, remainingTime));
      }
      
      setSubmitted(true);
      setEmail('');
      
      // Keep balloons visible until page reload - removed timeout that resets triggerAnimation
      
    } catch (error) {
      console.error('Error submitting email:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={`${styles.container} ${isDarkMode ? styles.darkMode : ''}`}>
      <Header />  
      <div className={styles.content}>
        <h1 type="title" style={{fontSize:"50px", textAlign:"center", lineHeight: '75px'}}>Get Early Access</h1>
        <p className={styles.subtitle}>We're expanding to more platforms, but we want to take our time to make sure we get it right.</p>
        
        <div className={styles.contentWrapper} data-testid="content-wrapper">
          <div className={styles.mainContent} data-testid="main-content">
            {/* <div className={styles.platformsContainer}>
              <div className={styles.platformCard}>
                <div className={styles.platformIcon}>
                  <Globe size={42} />
                </div>
                <h2 className={styles.platformTitle}>Web Version</h2>
                <p className={styles.platformDescription}>
                  Access all your travel plans from any browser. Plan trips, manage itineraries, and collaborate with friends directly from your computer.
                </p>
              </div>
              
              <div className={styles.platformCard}>
                <div className={styles.platformIcon}>
                  <Smartphone size={42} />
                </div>
                <h2 className={styles.platformTitle}>Android App</h2>
                <p className={styles.platformDescription}>
                  Bringing the full experience to Android users. Enjoy seamless trip planning, offline maps, and all premium features on your Android device.
                </p>
              </div>
            </div> */}

            <div className={styles.signupContainer}>
              <h3 className={styles.signupTitle}>Be the first to know when we launch</h3>
              <p className={styles.signupDescription}>Get early access and exclusive offers by signing up with your email.</p>
              
              <form className={styles.emailForm} onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                  {/* <label htmlFor="email" className={isDarkMode ? styles.darkLabel : ''}><b>Email</b></label> */}
                  <input 
                    type="email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Your email address"
                    className={`${styles.input} ${isDarkMode ? styles.darkInput : ''} ${error ? styles.inputError : ''} ${triggerAnimation ? styles.inputSuccess : ''}`}
                    required
                  />
                  {error && <p className={styles.errorMessage}>{error}</p>}
                  {submitted && <p className={styles.successMessage}>Thank you! We'll keep you updated.</p>}
                </div>
                
                {/* Honeypot field - hidden from humans but visible to bots */}
                <div className={styles.honeypotField}>
                  <label htmlFor="website">Website</label>
                  <input
                    type="text"
                    id="website"
                    name="website"
                    value={honeypot}
                    onChange={handleHoneypotChange}
                    autoComplete="off"
                  />
                </div>
                
                <button 
                  type="submit" 
                  className={`${styles.submitButton} ${isDarkMode ? styles.darkSubmitButton : ''} ${triggerAnimation ? styles.submitSuccess : ''}`}
                  disabled={isSubmitting}
                >
                  <div className={styles.submitButtonText}>
                    {isSubmitting ? 'Submitting...' : submitted ? 'Submitted' : 'Sign Up'}
                  </div>
                </button>
              </form>
              <p className={styles.privacyNote}>
                We will not spam you or share your email with anyone. By signing up, you agree to our <a href="/privacy-policy" className={styles.textLink}>Privacy Policy</a> and <a href="/terms-of-service" className={styles.textLink}>Terms of Service</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ThreeJSBalloons triggerAnimation={triggerAnimation} />
    </div>
  );
};

export default ComingSoon;
