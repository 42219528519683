import React, { useContext } from 'react';
import footer_styles from './footer.module.css';
import { Link } from 'react-router-dom';
import Stamp from './stamp';
import { DarkModeContext } from '../DarkModeProvider';
import { MoveUpRight } from 'lucide-react';

const Footer = () => {
    const { isDarkMode } = useContext(DarkModeContext);
    
    return (
      <div className={`${footer_styles.main_div} ${isDarkMode ? footer_styles.dark_mode : ''}`}>
        <svg viewbox="0 0 0 0" width="0" height="0">
          <defs>
            <filter id="noise">
              <feTurbulence type="fractalNoise" baseFrequency="0.1" numOctaves="10" stitchTiles="stitch" />
              <feColorMatrix type="matrix" values="0.33 0.33 0.33 0 0  0.33 0.33 0.33 0 0  0.33 0.33 0.33 0 0  0 0 0 1 0" />
              <feComponentTransfer>
                <feFuncR type="linear" slope="2" />
                <feFuncG type="linear" slope="2" />
                <feFuncB type="linear" slope="2" />
                <feFuncA type="linear" slope="0.5" />
              </feComponentTransfer>
              <feComposite operator="in" in2="SourceAlpha" result="composite"/>
              <feBlend in="SourceGraphic" in2="composite" mode="color-burn" />
            </filter>
          </defs>
        </svg>
        <div className={footer_styles.footer_div}>
          {/* Main footer content wrapper with grid and stamp */}
          <div className={footer_styles.footer_content_wrapper}>
            {/* Updated footer with responsive grid layout */}
            <div className={footer_styles.footer_grid_container}>
              {/* Company Links */}
              <div className={footer_styles.footer_grid_section}>
                <h4 className={`${footer_styles.footer_heading} ${isDarkMode ? footer_styles.dark_heading : ''}`}>Company</h4>
                {/* <Link to="/about" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>About Us</Link> */}
                <Link to="/contact" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>Contact</Link>
                {/* <Link to="/press-kit" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>Press Kit</Link> */}
              </div>

              {/* Resources Links */}
              <div className={footer_styles.footer_grid_section}>
                <h4 className={`${footer_styles.footer_heading} ${isDarkMode ? footer_styles.dark_heading : ''}`}>Resources</h4>

                {/* <Link to="/help-center" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>Help</Link> */}
                <Link to="/changelog" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>Changelog</Link>
                {/* <Link to="/documentation" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>Documentation</Link> */}
              </div>

              {/* Legal Links */}
              <div className={footer_styles.footer_grid_section}>
                <h4 className={`${footer_styles.footer_heading} ${isDarkMode ? footer_styles.dark_heading : ''}`}>Legal</h4>
                <Link to="/terms-of-service" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>Terms of Service</Link>
                <Link to="/privacy-policy" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>Privacy Policy</Link>
                {/* <Link to="/cookie-policy" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>Cookie Policy</Link>
                <Link to="/accessibility" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>Accessibility</Link> */}
              </div>

              {/* Social Links */}
              <div className={footer_styles.footer_grid_section}>
                <h4 className={`${footer_styles.footer_heading} ${isDarkMode ? footer_styles.dark_heading : ''}`}>Connect</h4>
                {/* <a href="https://www.linkedin.com/company/sailcompany" target="_blank" rel="noopener noreferrer" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>Sail</a> */}
                <a href="https://twitter.com/sailvacations" target="_blank" rel="noopener noreferrer" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>Twitter <MoveUpRight style={{height:'12px'}}/></a>
                <a href="https://www.instagram.com/sailvacations" target="_blank" rel="noopener noreferrer" className={`${footer_styles.footer_link} ${isDarkMode ? footer_styles.dark_link : ''}`}>Instagram <MoveUpRight style={{height:'12px'}}/></a>
              </div>
            </div>

            {/* Stamp positioned to the right on desktop, below on mobile */}
            <div className={footer_styles.stamp_container}>
              <div className={footer_styles.stamp_wrapper}>
                <Stamp />
              </div>
            </div>
          </div>

          {/* Copyright and bottom links */}
          <div className={footer_styles.footer_bottom_div}>
            <p className={`${footer_styles.copyright} ${isDarkMode ? footer_styles.dark_copyright : ''}`}>© 2025 Sail LLC. All rights reserved.</p>
          </div>
        </div>
      </div>
    );
};

export default Footer;
