import React from 'react';
import styles from './last_update.module.css';
import { MoveUpRight} from 'lucide-react';
import { Link } from 'react-router-dom';
const LastUpdate = () => {
    return (
        <Link to="/changelog#1.1.1" style={{marginTop: '40px'}}>
            <div className={styles.lastUpdateContainer}>
            <div className={styles.pulseCircle} />
            <p className={styles.lastUpdateText}>
                App updated <b>Mar 27th</b> <span className={styles.version}>v1.1.3<MoveUpRight style={{height:'12px'}}/></span> 
            </p>
        </div></Link>
    );
};

export default LastUpdate;
