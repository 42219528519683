import React from 'react';
import Header from './header';
import Footer from '../components/footer';
const TermsOfService = () => {

    return (
          <div>
          <Header/>
          <div className='privacy-policy' style={{textAlign:"left", padding:"20px", maxWidth:"600px", margin: "auto"}}>
          <h1 type="title" style={{fontSize:"50px", textAlign:"center", lineHeight: '75px'}}>Terms of Service</h1>
          <h3><b>Introduction</b></h3>
          <br />
          <b>Effective Date: August 15, 2024 </b>
          <br />
          <br />
                Welcome to Sail, a travel planning and social platform designed to help users create, share, and explore travel itineraries. By using our website (sailvacations.app) and mobile applications, you agree to the following Terms of Service.
                <br />
                <br />
                        <b>1. Acceptance of Terms</b>
                        <br />
                        <br />  
                        By accessing or using Sail, you agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree with any part of these terms, you may not use our services.
                <br />
                <br />
                        <b>2. Eligibility</b>
                        <br />
                        <br />
                        You must be at least 13 years old to use Sail. If you are under 18, you must have parental consent. By using Sail, you confirm that you meet these requirements.
                <br />
                <br />
                        <b>3. User Accounts</b>
                        <br />
                        <br />
                        You are responsible for maintaining the security of your account.
                        <br />
                        <br />
                        You agree not to share your account credentials with others.
                        <br />
                        <br />
                        We reserve the right to suspend or terminate accounts that violate these terms.
                <br />
                <br />
                        <b>4. User-Generated Content</b>
                        <br />
                        <br />
                        You retain ownership of any content you create or share on Sail. However, by posting content, you grant us a non-exclusive, worldwide, royalty-free license to use, display, and distribute it within the platform.
                        <br />
                        <br />
                        You agree not to post content that is unlawful, offensive, or infringes on the rights of others.
                <br />
                <br />
                        <b>5. Prohibited Activities</b>
                        <br />
                        <br />
                        When using Sail, you agree not to:
                        <br />
                        <br />
                        Use the service for any illegal purposes.
                        <br />
                        <br />
                        Post or share spam, misleading content, or unauthorized advertisements.
                        <br />
                        <br />
                        Attempt to gain unauthorized access to other users' accounts or Sail's infrastructure.
                <br />
                <br />
                        <b>6. Subscription and Payments</b>
                        <br />
                        <br />
                        Some features of Sail may be offered on a freemium basis, with premium services available for a fee.
                        <br />
                        <br />
                        All payments are non-refundable unless otherwise required by law.
                        <br />
                        <br />
                        Prices and features may be updated at our discretion with prior notice.
                <br />
                <br />
                        <b>7. Third-Party Links and Services</b>
                        <br />
                        <br />
                        Sail may contain links to third-party websites or services. We do not endorse or assume responsibility for any third-party content or services.
                <br />
                <br />
                        <b>8. Termination</b>
                        <br />
                        <br />
                        We reserve the right to suspend or terminate your access to Sail at any time if you violate these Terms of Service. You may also delete your account at any time through your account settings.
                <br />
                <br />
                        <b>9. Disclaimer of Warranties</b>
                        <br />
                        <br />
                        Sail is provided "as is" and "as available" without warranties of any kind. We do not guarantee uninterrupted service, accuracy, or reliability of the platform.
                <br />
                <br />
                        <b>10. Limitation of Liability</b>
                        <br />
                        <br />
                        To the maximum extent permitted by law, Sail and its affiliates are not liable for any indirect, incidental, or consequential damages arising from your use of the service.
                <br />
                <br />
                        <b>11. Changes to These Terms</b>
                        <br />
                        <br />
                        We may update these Terms of Service from time to time. We will notify users of significant changes by email or through the platform. Continued use of Sail after changes constitutes acceptance of the new terms.
                <br />
                <br />
                        <b>12. Contact Information</b>
                        <br />
                        <br />
                        If you have any questions about these Terms of Service, please contact us at support@sailvacations.app.
                <br />
                <br />
                </div>
                <Footer />
          </div>

    );
  };
  
  export default TermsOfService