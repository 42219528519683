import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as CANNON from 'cannon-es';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';

const ThreeJSBalloons = ({ triggerAnimation }) => {
  const mountRef = useRef(null);
  const balloons = [];

  const loader = new GLTFLoader();


  // Predefined positions for balloons with wider spread and depth
  const predefinedPositions = Array.from({ length: 24 }, () => (
    new THREE.Vector3(
      (Math.random() - 0.5) * 8, // Increase x position range for wider spread
      Math.random() * 10 + 6, // Increase y position range for wider spread
      (Math.random() - 0.5) * 2 // Random z position for depth
    )
  ));

  // Function to generate a random color
  // Commented out as it's not being used
  // const getRandomColor = () => {
  //   const letters = '56789ABCDEF';
  //   let color = '#';
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 11)];
  //   }
  //   return color;
  // };

  // Function to alternate between black and white
  const getBlackOrWhite = () => {
    return Math.random() > 0.5 ? '#FFFFFF' : '#000000';
  };

  useEffect(() => {
    if (!triggerAnimation) return; // Only proceed if animation is triggered

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    mountRef.current.appendChild(renderer.domElement);

    camera.position.z = 5;

    // Set up physics world
    const world = new CANNON.World();
    world.gravity.set(0, -0.1, 0); // Further reduced gravity for even slower fall

    // Create ground plane
    const groundBody = new CANNON.Body({
      mass: 0, // mass = 0 makes the body static
      shape: new CANNON.Plane(),
      position: new CANNON.Vec3(0, -window.innerHeight / 200, 0) // Adjust to bottom of screen
    });
    groundBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0);
    world.addBody(groundBody);

    // Add lighting
    const ambientLight = new THREE.AmbientLight(0x404040, 2); // Increase intensity
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0, 1, 1).normalize();
    scene.add(directionalLight);

    // Load EXR environment map
    const exrLoader = new EXRLoader();
    exrLoader.load('/hdri.exr', (texture) => {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      scene.background = texture;
      scene.environment = texture;
      scene.background = null; // Keep the background transparent

      loader.load('/balloon.glb', (gltf) => {
        predefinedPositions.forEach((position) => {
          const balloon = gltf.scene.clone();
          balloon.position.copy(position);
          balloon.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, Math.random() * Math.PI * 2); // Random initial rotation
          scene.add(balloon);

          // Create physics body for the balloon
          const shape = new CANNON.Sphere(0.1);
          const body = new CANNON.Body({ mass: 1, shape });
          body.position.set(balloon.position.x, balloon.position.y, balloon.position.z);
          world.addBody(body);

          balloons.push({ mesh: balloon, body });

          balloon.traverse((child) => {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial({
                color: getBlackOrWhite(), // Apply black or white color
                metalness: 1, // High metalness for reflectivity
                roughness: 0.1, // Low roughness for a smooth surface
                envMap: texture, // Use EXR as environment map
              });
            }
          });
        });

        const animate = () => {
          requestAnimationFrame(animate);

          // Step the physics world
          world.step(1 / 60);

          // Update balloon positions and add spinning effect
          balloons.forEach(({ mesh, body }) => {
            mesh.position.copy(body.position);
            mesh.quaternion.copy(body.quaternion);
            // Add spinning effect
            mesh.rotation.x += 0.1;
            mesh.rotation.y += 0.02;
          });

          renderer.render(scene, camera);
        };

        animate();
      });
    });

    return () => {
      mountRef.current.removeChild(renderer.domElement);
    };
  }, [triggerAnimation]);

  return <div ref={mountRef} style={{ position: 'fixed', top: 0, left: 0, zIndex: 10, width: '100vw', height: '100vh', pointerEvents: 'none' }} />;
};

export default ThreeJSBalloons; 