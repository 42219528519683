import first_class from './first_class.module.css';
import React, { useEffect, useRef, forwardRef, useState } from 'react';
// import { LightningChargeFill } from 'react-bootstrap-icons';
// import Badge from '../../components/badge';
import FirstClassDetails from '../../components/first_class_details';
import sail_first_class_boat from '../../assets/sail_first_class_boat.webp';
import laurel_left_gray from '../../assets/laurel_left_gray.svg';
import laurel_right_gray from '../../assets/laurel_right_gray.svg';
import transition_2 from '../../assets/transition_2.svg';
import transition_3 from '../../assets/transition_3.svg';
import sail_iridescent_sticker from '../../assets/sail_iridescent_sticker.webp';
import sail_rainbow_sticker from '../../assets/sail_rainbow_sticker.webp';
import sail_retro from '../../assets/sail_retro.webp';
import sail_blue_background from '../../assets/sail_blue_background.webp';
import highlighter from '../../assets/highlighter.webp';


const FirstClass = forwardRef((props, ref) => {
    const textRef = useRef(null);
    const imageRef = useRef(null);

    const initialStickerPositions = {
        sail_retro: { x: 0, y: 0, rotation: 15, scale: 1 },
        sail_rainbow_sticker: { x: 0, y: 0, rotation: -5, scale: 1 },
        sail_iridescent_sticker: { x: 0, y: 0, rotation: 15, scale: 1 },
        sail_blue_background: { x: 0, y: 0, rotation: -10,  scale: 1 }
    };

    const [stickerPositions, setStickerPositions] = useState(initialStickerPositions);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleMouseDown = (e, sticker) => {
        e.target.style.cursor = 'grabbing';
        e.target.style.transition = 'none';

        const startX = e.clientX;
        const startY = e.clientY;
        const originalX = stickerPositions[sticker].x;
        const originalY = stickerPositions[sticker].y;
        const rotation = stickerPositions[sticker].rotation;
        const scale = 1.2;

        // Set the scale immediately on mouse down
        setStickerPositions((prevPositions) => ({
            ...prevPositions,
            [sticker]: { ...prevPositions[sticker], scale: scale }
        }));

        const handleMouseMove = (moveEvent) => {
            const deltaX = moveEvent.clientX - startX;
            const deltaY = moveEvent.clientY - startY;

            const rad = (Math.PI / 180) * rotation;
            const rotatedX = deltaX * Math.cos(rad) + deltaY * Math.sin(rad);
            const rotatedY = deltaY * Math.cos(rad) - deltaX * Math.sin(rad);

            const newX = originalX + rotatedX;
            const newY = originalY + rotatedY;

            // Calculate the distance from the center
            const distance = Math.sqrt(newX * newX + newY * newY);
            const maxRadius = 200; // Define the maximum radius

            // If the distance exceeds the max radius, adjust the position
            if (distance > maxRadius) {
                const angle = Math.atan2(newY, newX);
                const constrainedX = maxRadius * Math.cos(angle);
                const constrainedY = maxRadius * Math.sin(angle);

                setStickerPositions((prevPositions) => ({
                    ...prevPositions,
                    [sticker]: { ...prevPositions[sticker], x: constrainedX, y: constrainedY, rotation, scale: scale }
                }));
            } else {
                setStickerPositions((prevPositions) => ({
                    ...prevPositions,
                    [sticker]: { ...prevPositions[sticker], x: newX, y: newY, rotation, scale: scale }
                }));
            }
        };

        const handleMouseUp = () => {
            e.target.style.cursor = 'grab';
            e.target.style.transition = 'transform 0.5s ease';

            setStickerPositions((prevPositions) => ({
                ...prevPositions,
                [sticker]: { ...initialStickerPositions[sticker] }
            }));
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    useEffect(() => {
        if (textRef.current && imageRef.current) {
            // Set the image width to match the text width
            const textWidth = textRef.current.offsetWidth;
            imageRef.current.style.width = `${textWidth/2}px`;
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={first_class.content} ref={ref} style={{borderTop: isMobile ? 'none': '1px solid #353535'}}>
                    <img className={first_class.transitionImage} src={transition_2} alt="sail_logo"/>

            <div className={first_class.first_class_div} style={{"padding-top": "100px","padding-bottom": "100px", borderBottom: isMobile ? 'none': '1px solid #353535'}}>
                <div className={first_class.first_class_header}>
                <img src={laurel_left_gray} type="laurel" alt="sail_logo" />
                <h2 ref={textRef} type="title"><span>Sail First Class</span></h2>
                    <img src={laurel_right_gray} type="laurel" alt="sail_logo" />
                </div>
                <img ref={imageRef} src={highlighter}  alt="sail_logo" type="highlighter"/>
                <div className={first_class.first_class_content}>
                    <div className={first_class.first_class_content_main}>
                    <img src={sail_first_class_boat}  alt="sail_logo" type="first_class_img"/>

                        <h2>
                        Do more, <span className={first_class.glowing}><b>faster</b></span>
                            {/* <Badge word="faster" color={"#FFC921"} textColor='black' textStyle={"italic"} IconComponent={LightningChargeFill}/> */}
                        </h2>
                        <p style={{fontSize: "30px","textAlign":"center", lineHeight: "40px", margin: "25px", fontWeight: "350"}}>
                            For our power planners, we offer a variety of tools to help you organize your trips faster and more efficiently. 
                        </p>
                        
                        <FirstClassDetails/>
                    </div>
                    
                </div>
            </div>
            <img className={first_class.transitionImage} src={transition_3}  alt="sail_logo" type="transition2" />
            <div className={first_class.sticker_div}>
                <img
                    src={sail_retro}
                    alt="sail_logo"
                    style={{ 
                        transform: `translate(${stickerPositions.sail_retro.x}px, ${stickerPositions.sail_retro.y}px) rotate(${stickerPositions.sail_retro.rotation}deg) scale(${stickerPositions.sail_retro.scale})`,
                        cursor: 'grab',
                        transition: 'transform 0.5s ease'
                    }}
                    onMouseDown={(e) => handleMouseDown(e, 'sail_retro')}
                />
                <img
                    src={sail_rainbow_sticker}
                    alt="sail_logo"
                    style={{ 
                        transform: `translate(${stickerPositions.sail_rainbow_sticker.x}px, ${stickerPositions.sail_rainbow_sticker.y}px) rotate(${stickerPositions.sail_rainbow_sticker.rotation}deg) scale(${stickerPositions.sail_rainbow_sticker.scale}`,
                        cursor: 'grab',
                        transition: 'transform 0.5s ease'
                    }}
                    onMouseDown={(e) => handleMouseDown(e, 'sail_rainbow_sticker')}
                />
                <img
                    src={sail_iridescent_sticker}
                    alt="sail_logo"
                    style={{ 
                        transform: `translate(${stickerPositions.sail_iridescent_sticker.x}px, ${stickerPositions.sail_iridescent_sticker.y}px) rotate(${stickerPositions.sail_iridescent_sticker.rotation}deg) scale(${stickerPositions.sail_iridescent_sticker.scale}`,
                        cursor: 'grab',
                        transition: 'transform 0.1s cubic-bezier(0.34, 1.56, 0.64, 1)'
                    }}
                    onMouseDown={(e) => handleMouseDown(e, 'sail_iridescent_sticker')}
                />
                <img
                    src={sail_blue_background}
                    alt="sail_logo"
                    style={{ 
                        transform: `translate(${stickerPositions.sail_blue_background.x}px, ${stickerPositions.sail_blue_background.y}px) rotate(${stickerPositions.sail_blue_background.rotation}deg) scale(${stickerPositions.sail_blue_background.scale}`,
                        cursor: 'grab',
                        transition: 'transform 0.5s ease'
                    }}
                    onMouseDown={(e) => handleMouseDown(e, 'sail_blue_background')}
                />
            </div>
        </div>
    );
});

export default FirstClass;