import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './changelog.module.css';
import { CheckIcon, MoveUpRight, Link2 } from 'lucide-react';
import Header from './header';
import Footer from '../components/footer';
import { DarkModeContext } from '../DarkModeProvider';
import TableOfContents from './TableOfContents';

const Changelog = () => {
  const { isDarkMode } = useContext(DarkModeContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeSection, setActiveSection] = useState(0);
  const [copiedVersion, setCopiedVersion] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const timelineItemRefs = useRef([]);
  const timelineRef = useRef(null);
  
  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      setIsMobile(newIsMobile);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  useEffect(() => {
    // Function to find the section closest to the center of the viewport
    const findClosestSection = () => {
      const viewportHeight = window.innerHeight;
      const viewportCenter = window.scrollY + viewportHeight / 2;
      
      let closestSection = 0;
      let minDistance = Infinity;
      
      timelineItemRefs.current.forEach((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const sectionCenter = window.scrollY + rect.top + rect.height / 2;
          const distance = Math.abs(sectionCenter - viewportCenter);
          
          if (distance < minDistance) {
            minDistance = distance;
            closestSection = index;
          }
        }
      });
      
      setActiveSection(closestSection);
    };
    
    // Call the function on scroll
    const handleScroll = () => {
      window.requestAnimationFrame(() => {
        findClosestSection();
      });
    };
    
    // Initial check
    findClosestSection();
    
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  // Add effect to handle initial hash
  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      const index = changes.findIndex(change => change.version.replace(/\./g, '') === hash);
      if (index !== -1) {
        // Small delay to ensure DOM is ready
        setTimeout(() => scrollToSection(index), 100);
      }
    }
  }, [location]);

  const scrollToSection = (index) => {
    if (timelineItemRefs.current[index]) {
      const element = timelineItemRefs.current[index];
      const elementRect = element.getBoundingClientRect();
      const elementHeight = elementRect.height;
      const windowHeight = window.innerHeight;
      
      // Calculate the scroll position that would center the element
      const scrollPosition = window.scrollY + elementRect.top - (windowHeight / 2) + (elementHeight / 2);
      
      // Update URL with version hash
      const version = changes[index].version.replace(/\./g, '');
      window.history.replaceState(null, null, `#${version}`);
      
      // Smooth scroll to the calculated position
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    }
  };
  
  const handleFeedbackClick = () => {
    navigate('/contact');
  };
  
  const copyVersionLink = (version, e) => {
    e.preventDefault();
    e.stopPropagation();
    const versionHash = version.replace(/\./g, '');
    const url = `${window.location.origin}${window.location.pathname}#${versionHash}`;
    navigator.clipboard.writeText(url).then(() => {
      setCopiedVersion(version);
      setTimeout(() => setCopiedVersion(null), 2000); // Reset after 2 seconds
    });
  };
  
  const changes = [
    {
      version: '1.1.3',
      date: 'March 27, 2025',
      title: 'Smart Crop',
      description: 'Introduced Smart Crop: for magically cropping images to the correct aspect ratio.',
      details: [
        'Added Smart Crop for images with extreme aspect ratios',
        'Updated the onboarding UI',
        'Various bug fixes',
        'Various bug fixes'
      ]
    },
    {
      version: '1.1.2',
      date: 'March 21, 2025',
      title: 'Flight Data and UI Improvements',
      description: 'Fixed some more bugs from update 1.1.0.',
      details: [
        'Changed flight data provider to FlightAware',
        'Fixed a bug where flights with legs were being shown as direct',
        'Fixed trip sharing links',
        'Various UI improvements',
        'Various bug fixes'
      ]
    },
    {
      version: '1.1.1',
      date: 'March 15, 2025',
      title: 'Quality of Life Improvements',
      description: 'We made some quality of life improvements to the app.',
      details: [
        'Added autosave to the itinerary: no more losing your work',
        'Various bug fixes'
      ]
    },
    {
      version: '1.1.0',
      date: 'March 3, 2025',
      title: 'Flights and Routes',
      description: 'One of the most requested features is finally here.',
      details: [
        'Added the ability for users to add flights to the itinerary',
        'Added the ability for users to add routes to the itinerary',
        'Added the ability to minimized the itinerary, allowing for easier planning',
        'Added new info on the trip page. Categories, travelers, and more',
        'Various bug fixes'
      ]
    },
    {
      version: '1.0.2',
      date: 'October 10, 2024',
      title: 'Group Travel',
      description: 'Traveling with friends and family is now easier than ever.',
      details: [
        'Added the ability to add multiple users to a trip',
        'Improved the messaging features for better social traveling',
        'Various bug fixes'
      ]
    },
    {
      version: '1.0.1',
      date: 'October 10, 2024',
      title: 'Bug and Performance Fixes',
      description: 'We fixed a few bugs and improved the performance of the app based on initial feedback.',
      details: [
        'Fixed My Circle pagination performance',
        'Fixed crashing issues',
        'Improved performance in trip creation',
      ]
    },
    {
      version: '1.0.0',
      date: 'October 4, 2024',
      title: 'Initial Release',
      description: 'The Sail mobile app is now available for public use.',
      details: [
        'Added everything in the beta release (just better)',
        'Various bug fixes'
      ]
    },
    {
      version: 'beta',
      date: 'April 1, 2024',
      title: 'Public Beta Release',
      description: 'Ongoing development and testing of the application.',
      details: [
        'Built core itinerary building functionality',
        'Added trip sharing functionality',
        'Added user authentication',
        'Built real-time messanger',
        'Advanced budgeting tools',
        'Added locational weather prediction',
        'Added the packing list',
        'Allowed users to save trips',
        'Added free Sail First Class for testers',
        'Built all the maps functionality',

      ]
    }
  ];

  // Add an effect to log the height of the timeline for debugging
  useEffect(() => {
    if (timelineRef.current) {
      console.log('Timeline height:', timelineRef.current.offsetHeight);
    }
  }, []);


  return (
    <div className={`${styles.container} ${isDarkMode ? styles.darkMode : ''}`}>
      <Header />  
      <div className={styles.content}>
        <h1 type="title" style={{fontSize:"50px", textAlign:"center", lineHeight: '75px'}}>Changelog</h1>
        <p className={styles.subtitle}>Here's a few things we've been up to.</p>
        
        <div className={styles.contentWrapper} data-testid="content-wrapper">
          <div className={styles.mainContent} data-testid="main-content">
            <div className={styles.timeline} data-testid="timeline" ref={timelineRef}>
              {changes.map((change, index) => (
                <div 
                  key={index} 
                  className={styles.timelineItem}
                  ref={el => timelineItemRefs.current[index] = el}
                  data-index={index}
                  id={`changelog-item-${index}`}
                >
                  <div className={styles.timelineMarker}>
                    <div className={styles.circle}>
                      <CheckIcon className={styles.checkIcon} size={16} />
                    </div>
                    {index !== changes.length - 1 && <div className={styles.line}></div>}
                  </div>
                  
                  <div className={styles.timelineContent}>
                    <div className={styles.versionHeader}>
                      <div className={styles.versionWithLink}>
                        <span className={styles.version}>{change.version}</span>
                        <button 
                          className={styles.copyLinkButton}
                          onClick={(e) => copyVersionLink(change.version, e)}
                          title="Copy link to this version"
                        >
                          <Link2 size={14} />
                          {copiedVersion === change.version && (
                            <span className={styles.copiedTooltip}>Copied</span>
                          )}
                        </button>
                      </div>
                      <span className={styles.date}>{change.date}</span>
                    </div>
                    <h4 className={styles.changeTitle}>{change.title}</h4>
                    <b >{change.description}</b>
                    <ul className={styles.details}>
                      {change.details.map((detail, i) => (
                        <li key={i}>{detail}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
            
            <div style={{ 
              display: "flex", 
              justifyContent: "flex-end", 
              width: "100%", 
              marginTop: "30px", 
              marginBottom: "20px",
            }}>
              <button 
                onClick={handleFeedbackClick}
                className={`${styles.submitButton} ${isDarkMode ? styles.darkSubmitButton : ''}`}

                // style={{
                //   backgroundColor: isDarkMode ? "#ffffff" : "#000000",
                //   color: isDarkMode ? "#000000" : "#ffffff",
                //   border: "none",
                //   borderRadius: "12px",
                //   padding: isMobile ? "6px 12px" : "8px 16px",
                //   border: "2px solid #aaaaaa50",
                //   fontSize: isMobile ? "12px" : "14px",
                //   fontWeight: "600",
                //   cursor: "pointer",
                //   boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                //   transition: "all 0.3s ease",
                //   whiteSpace: "nowrap",
                // }}
              >
                <div className={styles.submitButtonText}>
                  Got an idea for us? Let us know <MoveUpRight size={16} />
                </div>
              </button>
            </div>
          </div>
          
          {!isMobile && (
              // <div style={{ 
              //   position: 'sticky',
              //   top: '0',
              //   width: '300px',
              //   height: '100%',
              //   // border: '3px solid purple',
              //   overflow: 'visible'

              // }}>
                <TableOfContents 
                  changes={changes}
                  activeSection={activeSection}
                  scrollToSection={scrollToSection}
                  isMobile={isMobile}
                />
              // </div>
          )}
        </div>
        
          {/* <div className={styles.stickyBar}>
            TEST
          </div> */}
      </div>
      
      <Footer />
    </div>
  );
};

export default Changelog;
