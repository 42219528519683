import React, { useRef, useState, useEffect, useContext } from 'react';
import hero_styles from './hero.module.css';
import Toolbar from '../../components/toolbar';
// import hero from '../../assets/hero.png';
// import AppStore from '../../assets/appstore.svg';
import RotateWord from '../../components/rotate_word';
import { DarkModeContext } from '../../DarkModeProvider';
import hero3 from '../../assets/hero3.webp';
// import ScrollHero from '../features/scroll_hero';
// import styles from '../index.module.css';
import CTA from '../../components/cta';
import LastUpdate from '../../components/last_update';



const Hero = ({ onFeaturesClick, onFirstClassClick }) => {
  const logoDivRef = useRef(null);
  const titleH1Ref = useRef(null);
  const buttonDivRef = useRef(null);
  const requestRef = useRef(null);
  const [totalHeight, setTotalHeight] = useState(0);
  const { isDarkMode } = useContext(DarkModeContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleScroll = () => {
        const logoDivHeight = logoDivRef.current ? logoDivRef.current.offsetHeight : 0;
        const titleH1Height = titleH1Ref.current ? titleH1Ref.current.offsetHeight : 500;
        const buttonDivHeight = buttonDivRef.current ? buttonDivRef.current.offsetHeight : 0;

        const total = logoDivHeight + titleH1Height + buttonDivHeight;
        setTotalHeight(total);
        console.log('total height', total);
    };

    const throttledHandleScroll = () => {
        if (!requestRef.current) {
            requestRef.current = requestAnimationFrame(() => {
                handleScroll();
                requestRef.current = null;
            });
        }
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('scroll', throttledHandleScroll);
    window.addEventListener('resize', handleResize);
    setIsMobile(window.innerWidth <= 768);

    return () => {
        window.removeEventListener('scroll', throttledHandleScroll);
        window.removeEventListener('resize', handleResize);
        if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
        }
    };
  }, []);


  const words = [
    'Organize your itinerary.', 
    'Collaborate with friends.', 
    'Plan your dream vacations.', 
    'Find your community.', 
    'Explore new destinations.',
  ];

  return (
    <div className={hero_styles.container}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', zIndex: 3}}>
          <Toolbar index={true} totalHeight={totalHeight} onFeaturesClick={onFeaturesClick} onFirstClassClick={onFirstClassClick}/>
          <h1 ref={titleH1Ref} type="title">The Ultimate <br /> Travel Companion</h1>
          {!isMobile && (
            <p className={hero_styles.gradientText} style={{fontSize: "14pt", marginTop: "50px", width: "700px", lineHeight: "2", color: "transparent"}}>
              From dream to destination, <b >Sail makes vacation planning effortless</b>. Build itineraries with friends, get recommendations, and share your journeys.
            </p>
          )}
          <CTA />
          <LastUpdate/>

          
          
          {/* <a style={{display: 'flex', alignItems: "center", marginBottom: "50px", width:'250px'}} href="https://apps.apple.com/us/app/sail-vacations-social-travel/id6471536080?ign-itscg=30200&ign-itsct=apps_box_link&mttnsubad=6471536080">
            <img src={AppStore} alt="sail_logo" style={{filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2))", margin: '0px', width: "100%"}} />
          </a> */}
          
          
        {/* <img src={hero}  alt="Sail Hero"/> */}
        {isMobile && (
          <img loading="eager" rel="preload" as="image" width={500} height={500} src={hero3}  alt="Sail Hero" className={hero_styles.heroImage}/>
        )}
          {!isMobile && (
              <div style={{marginTop: "100px",marginBottom: "200px", position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100vw", height: '100vh'}}>

                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  {/* <ScrollHero className={styles.hideOnMobile}/> */}
                  <img loading="eager" rel="preload" as="image" width={500} height={500} src={hero3}  alt="Sail Hero" className={hero_styles.heroImage}/>

                </div>
                <RotateWord phrases={words} style={{ position: 'absolute', zIndex: '3' }}/>
            </div>
          )}
          
                
      </div>
    </div>
  );
};

export default Hero;
