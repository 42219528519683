import React, { useContext, useEffect, useState } from 'react';
import WordSwitch from './word_switch';
import styles from './word_switch_grid.module.css'; // Import the CSS file for grid styling
import { DarkModeContext } from '../../DarkModeProvider';
import hero_styles from './hero.module.css';
import { ArrowRight } from 'lucide-react';
import CTA from '../../components/cta';



const WordSwitchGrid = () => {

  const { isDarkMode } = useContext(DarkModeContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

   useEffect(() => {

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    setIsMobile(window.innerWidth <= 768);

    return () => {
        window.removeEventListener('resize', handleResize);

    };
  }, []);

    return (
        <div className={styles.container}>
            <div className={styles.grid}>
                {Array.from({ length: 49 }).map((_, index) => (
                    <WordSwitch key={index} />
                ))}
            </div>
            <div className='overlay' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <p style={{fontSize: "20pt"}}>For Wherever You Go.</p>

                <CTA />
            
            </div>
        </div>
    );
};

export default WordSwitchGrid;
