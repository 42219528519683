import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import styles from './contact.module.css';
import Accordion from '../components/accordian';
import { AccordionItem } from '../components/accordian';
import { DarkModeContext } from '../DarkModeProvider';
import Footer from '../components/footer';
const Contact = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [honeypot, setHoneypot] = useState(''); // Honeypot field to catch bots
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const navigate = useNavigate();
  const { isDarkMode } = useContext(DarkModeContext);

  // Add viewport meta tag for proper mobile rendering
  useEffect(() => {
    // Check if viewport meta tag exists
    let viewportMeta = document.querySelector('meta[name="viewport"]');
    
    // If it doesn't exist, create it
    if (!viewportMeta) {
      viewportMeta = document.createElement('meta');
      viewportMeta.name = 'viewport';
      document.head.appendChild(viewportMeta);
    }
    
    // Set the content attribute to ensure proper mobile scaling
    viewportMeta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
    
    // Cleanup function to restore original viewport if needed
    return () => {
      if (viewportMeta && viewportMeta.parentNode) {
        viewportMeta.content = 'width=device-width, initial-scale=1.0';
      }
    };
  }, []);

  // FAQ data organized by categories
  const generalFaqs = [
    {
      title: "What is Sail?",
      content: "Sail is a social travel planning app that helps you create, share, and discover itineraries with friends and the travel community.",
    },
    {
      title: "Is Sail free to use?",
      content: "Yes! You can use Sail for free to plan and share trips. We also offer premium features for advanced trip planning."
    }
  ];

  const tripPlanningFaqs = [
    {
      title: "Can I collaborate on a trip with friends?",
      content: "Yes! You can invite friends to your itinerary so they can view, edit, and contribute to the plan."
    },
    {
      title: "Does Sail offer AI-powered trip planning?",
      content: "Yes, Sail uses AI to suggest personalized itineraries, packing lists, and more based on your preferences and previous trips."
    },
    {
      title: "Can I sync my Sail trips with my calendar?",
      content: "Yes! You can subscribe to your Sail trips as a dynamic calendar feed that updates automatically."
    }
  ];

  const communitySocialFaqs = [
    {
      title: "How can I discover itineraries from other travelers?",
      content: "You can explore public itineraries, follow other users, and get inspiration from trending trips."
    },
    {
      title: "Can I share my itinerary with friends?",
      content: "Yes! You can share your itinerary with friends by sending them a link or by adding them to your itinerary."
    },
    {
      title: "Can I share my itinerary with people who don't have Sail?",
      content: "Yes! You can share your itinerary with people who don't have Sail by sending them a link to the itinerary. They will be able to view the itinerary in the browser."
    }
  ];

  const technicalSupportFaqs = [
    {
      title: "Does Sail have a web or Android version?",
      content: "Currently, Sail is available on iOS, but we are working on expanding to other platforms."
    },
    {
      title: "I found a bug. How do I report it?",
      content: "You can contact us through the app or email support@sailvacations.app."
    },
    {
      title: "How do I delete my account?",
      content: "You can request account deletion from the app settings or contact our support team."
    }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (honeypot) {
      setEmail('');
      setMessage('');
      navigate('/');
      return;
    }
    
    setIsSubmitting(true);
    setSubmitError(null);
    
    try {
      const cloudFunctionUrl = 'https://us-central1-vacay-project.cloudfunctions.net/submitContactForm';
      
      const response = await fetch(cloudFunctionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          message,
          timestamp: Date.now(), // Add timestamp to prevent replay attacks
        }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to submit form');
      }
      
      console.log('Form submitted successfully');
      // Clear form
      setEmail('');
      setMessage('');
      // Redirect to home page
      navigate('/');
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitError(error.message || 'An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={`${styles.container} ${isDarkMode ? styles.darkMode : ''}`}>
      <Header />
      <div className={styles.contentWrapper}>
        {/* Left column - Contact form */}
        <div className={`${styles.formSection} ${isDarkMode ? styles.darkFormSection : ''}`}>
          <h1 className={`${styles.title} ${isDarkMode ? styles.darkText : ''}`}>Contact Us</h1>
          <h3 className={`${styles.subtitle} ${isDarkMode ? styles.darkSubtitle : ''}`}>Have a question? Send us a message!</h3>
          
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={isDarkMode ? styles.darkLabel : ''}><b>Email</b></label>
              <input
                type="email"
                id="email"
                className={`${styles.input} ${isDarkMode ? styles.darkInput : ''}`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Your email address"
              />
            </div>
            
            {/* Honeypot field - hidden from humans but visible to bots */}
            <div className={styles.honeypotField}>
              <label htmlFor="website">Website</label>
              <input
                type="text"
                id="website"
                name="website"
                value={honeypot}
                onChange={(e) => setHoneypot(e.target.value)}
                autoComplete="off"
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="message" className={isDarkMode ? styles.darkLabel : ''}><b>What can we help with?</b></label>
              <textarea
                id="message"
                className={`${styles.textarea} ${isDarkMode ? styles.darkTextarea : ''}`}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                placeholder="Type your message here..."
              />
            </div>
            
            {submitError && (
              <div className={styles.errorMessage}>
                {submitError}
              </div>
            )}
            
            <button 
              type='submit' 
              className={`${styles.submitButton} ${isDarkMode ? styles.darkSubmitButton : ''}`}
              disabled={isSubmitting}
            >
              <div className={styles.submitButtonText}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </div>
            </button>
          </form>
        </div>
        
        {/* Right column - FAQs */}
        <div className={`${styles.faqSection} ${isDarkMode ? styles.darkFaqSection : ''}`}>
          <h1 className={`${styles.title} ${isDarkMode ? styles.darkText : ''}`}>Frequently Asked Questions</h1>
          <h4 className={`${styles.accordionSectionTitle} ${isDarkMode ? styles.darkAccordionTitle : ''}`}>General</h4>
          <Accordion>
            {generalFaqs.map((item, index) => (
              <AccordionItem 
                key={index}
                title={item.title}
                defaultOpen={item.defaultOpen}
              >
                {item.content}
              </AccordionItem>
            ))}
          </Accordion>
          
          <h4 className={`${styles.accordionSectionTitle} ${isDarkMode ? styles.darkAccordionTitle : ''}`}>Trip Planning</h4>
          <Accordion>
            {tripPlanningFaqs.map((item, index) => (
              <AccordionItem 
                key={index}
                title={item.title}
                defaultOpen={item.defaultOpen}
              >
                {item.content}
              </AccordionItem>
            ))}
          </Accordion>
          
          <h4 className={`${styles.accordionSectionTitle} ${isDarkMode ? styles.darkAccordionTitle : ''}`}>Community & Social</h4>
          <Accordion>
            {communitySocialFaqs.map((item, index) => (
              <AccordionItem 
                key={index}
                title={item.title}
                defaultOpen={item.defaultOpen}
              >
                {item.content}
              </AccordionItem>
            ))}
          </Accordion>
          
          <h4 className={`${styles.accordionSectionTitle} ${isDarkMode ? styles.darkAccordionTitle : ''}`}>Technical & Support</h4>
          <Accordion>
            {technicalSupportFaqs.map((item, index) => (
              <AccordionItem 
                key={index}
                title={item.title}
                defaultOpen={item.defaultOpen}
              >
                {item.content}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;