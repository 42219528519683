import React, { useRef, useState, useContext } from 'react';
import Hero from './components/hero';
import Features from './components/features';
import Footer from '../components/footer';
import FirstClass from './components/first_class';
import FeaturesSection from './features/features';
import { DarkMode, LightMode } from '@mui/icons-material';
import { DarkModeContext } from '../DarkModeProvider';
import WordSwitch from './components/word_switch_grid';

const Index = () => {
  const featuresRef = useRef(null);
  const firstClassRef = useRef(null);
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  const [isBlurred, setIsBlurred] = useState(false);
  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const scrollToFeatures = () => {
    if (featuresRef.current) {
      featuresRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToFirstClass = () => {
    if (firstClassRef.current) {
      firstClassRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 768);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  return (
    <div>
      <button aria-label="Toggle dark mode" id="darkModeToggle" className='darkModeToggle' onClick={() => {
        toggleDarkMode();
        setIsBlurred(true);
        setTimeout(() => setIsBlurred(false), 300); // Remove blur after 300ms
      }}>
        <span className={`icon ${isBlurred ? 'blur' : ''}`}>
          {isDarkMode ? <LightMode/> : <DarkMode/>}
        </span>
      </button>

   
      <Hero onFeaturesClick={scrollToFeatures} onFirstClassClick={scrollToFirstClass}/>
      <Features />
      <FeaturesSection ref={featuresRef}/>
      <FirstClass ref={firstClassRef}/>
      <WordSwitch />
      <Footer />
    </div>
  );
};

export default Index;