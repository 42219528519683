import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import styles from './rotate_word.module.css'; // Import the CSS file
import { DarkModeContext } from '../DarkModeProvider';
import { useContext } from 'react';

import { useScroll } from "motion/react"



const RotateWord = ({ phrases = [] }) => { // Set default value for phrases
  const { scrollYProgress } = useScroll()

  const [scrollProgress, setScrollProgress] = useState(0);
  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initialize isMobile

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 768); // Update isMobile based on window width
  //   };

  //   window.addEventListener('resize', handleResize); // Add event listener

  //   return () => window.removeEventListener('resize', handleResize); // Cleanup event listener
  // }, []);

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      setScrollProgress(latest);
    });
  }, [scrollYProgress]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const { isDarkMode } = useContext(DarkModeContext);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 1300);

    return () => clearInterval(interval);
  }, [phrases.length]);

  return (
    <div className={styles.rotateWord} style={{gap: `${Math.max(-scrollProgress * 5700 + 550, 300)}px`}}>
      <div className={styles.container}>
        {phrases.map((phrase, index) => {
            let className;
            if (index === currentIndex) {
            className = styles.active;
            } else if (index === (currentIndex + 1) % phrases.length) {
            className = styles.inactivePlusOneIndex;
            } else if (index === (currentIndex + 2) % phrases.length) {
            className = styles.inactivePlusTwoIndex;
            } else if (index === (currentIndex - 1 + phrases.length) % phrases.length) {
            className = styles.inactiveMinusOneIndex;
            } else if (index === (currentIndex - 2 + phrases.length) % phrases.length) {
            className = styles.inactiveMinusTwoIndex;
            } else {
            className = styles.inactive;
            }

            return (
            <div key={index} className={`${className} ${isDarkMode ? styles.darkMode : ''}`} style={{
                textShadow: !isDarkMode ? '0 2px 10px rgba(255,255,255,0.4)' : '0 2px 10px rgba(0,0,0,0.3)'
            }}>
                {phrase}
            </div>
            );
        })}
        </div>

        <div style={{width: '415px' }}>
          <b>All in one app.</b>
        </div>
    </div>

  );
};

RotateWord.propTypes = {
  phrases: PropTypes.arrayOf(PropTypes.string), // Define prop types
};

export default RotateWord;


