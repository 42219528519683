import styles from "./card_2.module.css";

const Card = ({ title, description, image, isImageBottomAligned }) => {
    return (
        <div class="card">
            <div className={styles.cardContent}>
                <h3 className={styles.title} style={{lineHeight: "1.3"}}><b>{title}</b></h3>
                <p style={{ textAlign: "left" }}>{description}</p>
            </div>
            <div className={`${styles.image} ${isImageBottomAligned ? styles.imageBottom : styles.imageCenter}`}>
                <img 
                src={image} 
                alt={title} 
                />
            </div>
        </div>
    );
};

export default Card;