import React, { useState, useRef, useEffect, useContext } from 'react';
import { ChevronDown } from 'lucide-react';
import styles from './accordian.module.css';
import { DarkModeContext } from '../DarkModeProvider';


/**
 * Accordion Item component
 * @param {Object} props
 * @param {string} props.title - The title of the accordion item
 * @param {React.ReactNode} props.children - The content to be displayed when expanded
 * @param {boolean} props.defaultOpen - Whether the accordion is open by default
 * @param {string} props.className - Additional class name for the accordion item
 */
export const AccordionItem = ({ title, children, defaultOpen = false, className = '' }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const contentRef = useRef(null);
  const [height, setHeight] = useState(defaultOpen ? 'auto' : '0px');
  const { isDarkMode } = useContext(DarkModeContext);

  useEffect(() => {
    if (isOpen) {
      const contentHeight = contentRef.current.scrollHeight;
      setHeight(`${contentHeight}px`);
      // After the transition is complete, set height to auto to handle content changes
      setTimeout(() => {
        setHeight('auto');
      }, 300);
    } else {
      // First set a fixed height to enable the transition
      if (height === 'auto') {
        const contentHeight = contentRef.current.scrollHeight;
        setHeight(`${contentHeight}px`);
        // Force a reflow - fix the ESLint error by using the value
        const forceReflow = contentRef.current.offsetHeight;
        void forceReflow; // Explicitly void the expression to show intent
      }
      // Then animate to 0
      setTimeout(() => {
        setHeight('0px');
      }, 10);
    }
  }, [isOpen]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  // Define dynamic styles based on dark mode
  const headerStyle = {
    backgroundColor: isDarkMode ? 'rgba(28, 28, 30)' : '#fff',
    fontFamily: 'Inter',
    borderRadius: '0px',    
  };

  const titleStyle = {
    color: isDarkMode ? '#fff' : '#000000',
    fontFamily: 'Inter',
  };

  const iconStyle = {
    color: isDarkMode ? (isOpen ? '#ccc' : '#999') : (isOpen ? '#333' : '#666'),
  };

  const bodyStyle = {
    color: isDarkMode ? '#b0b0b0' : '#666',
    fontFamily: 'Inter',
    backgroundColor: isDarkMode ? 'rgba(28, 28, 30)' : '#fff',
  };

  const accordionItemStyle = {
    backgroundColor: isDarkMode ? 'rgba(28, 28, 30)' : '#fff',
    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',

  };

  return (
    <div className={`${styles.accordionItem} ${className}`} style={accordionItemStyle}>
      <button 
        className={`${styles.accordionHeader} ${isOpen ? styles.active : ''}`}
        onClick={toggleAccordion}
        aria-expanded={isOpen}
        style={headerStyle}
      >
        <span className={styles.accordionTitle} style={titleStyle}>{title}</span>
        <span className={styles.accordionIcon} style={iconStyle}>
          <ChevronDown size={18} />
        </span>
      </button>
      <div 
        ref={contentRef}
        className={styles.accordionContent}
            style={{ height }}
        aria-hidden={!isOpen}
      >
        <div className={styles.accordionBody} style={bodyStyle}>
          {children}
        </div>
      </div>
    </div>
  );
};

/**
 * Accordion component - container for accordion items
 * @param {Object} props
 * @param {React.ReactNode} props.children - The AccordionItem components
 * @param {string} props.className - Additional class name for the accordion container
 */
const Accordion = ({ children, className = '' }) => {
  const { isDarkMode } = useContext(DarkModeContext);
  
  const containerStyle = {
    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  };

  return (
    <div className={`${styles.accordion} ${className}`} style={containerStyle}>
      {children}
    </div>
  );
};

export default Accordion;
