import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import styles from './TableOfContents.module.css';
import { DarkModeContext } from '../DarkModeProvider';
import { ChevronDown, ChevronUp, Search, X, Link2 } from 'lucide-react';
import { FixedSizeList as List } from 'react-window';
// Import placeholder for the custom SVG (to be added later)
import SlashKeySVG from '../assets/Frame 37.svg';

const TableOfContents = ({ 
  changes, 
  activeSection, 
  scrollToSection, 
  isMobile
}) => {
  const { isDarkMode } = useContext(DarkModeContext);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredChanges, setFilteredChanges] = useState(changes);
  const [viewMode, setViewMode] = useState('grouped');
  const [copiedVersion, setCopiedVersion] = useState(null);
  const searchInputRef = useRef(null);

  // Group changes by year
  const groupedChanges = useMemo(() => {
    return filteredChanges.reduce((groups, change, index) => {
      const year = change.date.split(', ')[1];
      if (!groups[year]) {
        groups[year] = [];
      }
      // Find the original index in the full changes array
      const originalIndex = changes.findIndex(c => 
        c.version === change.version && c.title === change.title
      );
      groups[year].push({ ...change, originalIndex });
      return groups;
    }, {});
  }, [filteredChanges, changes]);

  // Flatten the grouped changes for virtualized list (only used for search results)
  const flattenedItems = useMemo(() => {
    if (viewMode === 'flat') {
      return filteredChanges.map((change, index) => {
        const originalIndex = changes.findIndex(c => 
          c.version === change.version && c.title === change.title
        );
        return {
          type: 'item',
          data: { ...change, originalIndex }
        };
      });
    }

    const items = [];
    Object.entries(groupedChanges)
      .sort(([yearA], [yearB]) => parseInt(yearB) - parseInt(yearA)) // Sort years in descending order
      .forEach(([year, yearChanges]) => {
      // Add the year header
      items.push({ 
        type: 'header', 
        data: { year, isExpanded: expandedGroups[year] } 
      });
      
      // Add the items if the group is expanded
      if (expandedGroups[year]) {
        yearChanges.forEach(change => {
          items.push({ 
            type: 'item', 
            data: change 
          });
        });
      }
    });
    
    return items;
  }, [groupedChanges, expandedGroups, filteredChanges, changes, viewMode]);

  // Initialize expanded state for all groups when groupedChanges changes
  useEffect(() => {
    if (Object.keys(expandedGroups).length === 0 || 
        !Object.keys(expandedGroups).every(key => Object.keys(groupedChanges).includes(key))) {
      const initialExpandedState = {};
      
      // Get years in descending order
      const sortedYears = Object.keys(groupedChanges).sort((a, b) => parseInt(b) - parseInt(a));
      
      // Get current year
      const currentYear = new Date().getFullYear().toString();
      
      // Set only current year to be expanded by default
      sortedYears.forEach(year => {
        initialExpandedState[year] = year === currentYear;
      });

      setExpandedGroups(initialExpandedState);
    }
  }, [groupedChanges, expandedGroups]);

  // Filter changes based on search term
  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredChanges(changes);
      setViewMode('grouped');
      return;
    }
    
    const term = searchTerm.toLowerCase();
    const filtered = changes.filter(change => 
      change.version.toLowerCase().includes(term) || 
      change.title.toLowerCase().includes(term) ||
      change.description.toLowerCase().includes(term) ||
      change.date.toLowerCase().includes(term) ||
      change.details.some(detail => detail.toLowerCase().includes(term))
    );
    
    setFilteredChanges(filtered);
    
    // If searching, switch to flat view for better results display
    setViewMode('flat');
    
  }, [searchTerm, changes]);

  // Toggle group expansion
  const toggleGroup = (year) => {
    setExpandedGroups(prev => ({
      ...prev,
      [year]: !prev[year]
    }));
  };

  // Clear search
  const clearSearch = () => {
    setSearchTerm('');
    setViewMode('grouped');
  };

  const copyVersionLink = (version, e) => {
    e.preventDefault();
    e.stopPropagation();
    const versionHash = version.replace(/\./g, '');
    const url = `${window.location.origin}${window.location.pathname}#${versionHash}`;
    navigator.clipboard.writeText(url).then(() => {
      setCopiedVersion(version);
      setTimeout(() => setCopiedVersion(null), 2000); // Reset after 2 seconds
    });
  };

  // Add keyboard shortcut for search
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check if the key pressed is '/' and not in an input or textarea
      if (e.key === '/' && 
          !['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName)) {
        e.preventDefault();
        searchInputRef.current?.focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Render row for virtualized list
  const Row = ({ index, style }) => {
    const item = flattenedItems[index];
    
    if (item.type === 'header') {
      return (
        <div 
          style={{
            ...style,
            padding: '8px 10px',
            boxSizing: 'border-box',
            backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
            borderRadius: '8px',
            marginBottom: '4px'
          }}
          className={`${styles.tocYearHeader} ${isDarkMode ? styles.darkMode : ''}`}
          onClick={() => toggleGroup(item.data.year)}
        >
          <span className={`${styles.tocYear} ${isDarkMode ? styles.darkMode : ''}`}>{item.data.year}</span>
          {item.data.isExpanded ? 
            <ChevronUp size={16} color={isDarkMode ? '#aaa' : '#333'} /> : 
            <ChevronDown size={16} color={isDarkMode ? '#aaa' : '#333'} />
          }
        </div>
      );
    } else {
      const isActive = activeSection === item.data.originalIndex;
      return (
        <div 
          style={{
            ...style,
            padding: '8px 10px',
            boxSizing: 'border-box',
            backgroundColor: isActive 
              ? (isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.08)') 
              : 'transparent',
            borderRadius: '8px',
            marginBottom: '6px',
            fontWeight: isActive ? '500' : 'normal',
          }}
          className={`${styles.tocItem} ${isActive ? styles.tocItemActive : ''} ${isDarkMode ? styles.darkMode : ''}`}
          onClick={() => scrollToSection(item.data.originalIndex)}
        >
          <div className={styles.tocItemContent}>
            <div className={styles.tocVersionWrapper}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <span 
                  className={`${styles.tocVersion} ${isDarkMode ? styles.darkMode : ''}`}
                  style={{ color: 'rgb(52, 199, 89)' }}
                >
                  {item.data.version}
                </span>
                <span 
                  className={`${styles.tocItemTitle} ${isDarkMode ? styles.darkMode : ''}`}
                  style={{ color: isDarkMode ? '#bbb' : '#333' }}
                >
                  {item.data.title}
                </span>
              </div>
              <button 
                className={styles.tocCopyLinkButton}
                onClick={(e) => copyVersionLink(item.data.version, e)}
                title="Copy link to this version"
              >
                <Link2 size={12} />
                {copiedVersion === item.data.version && (
                  <span className={styles.tocCopiedTooltip}>Copied</span>
                )}
              </button>
            </div>
          </div>

        </div>
      );
    }
  };

  if (isMobile) {
    return null;
  }

  return (
      <div 
        className={`${styles.tableOfContents} ${isDarkMode ? styles.darkMode : ''}`}
        data-testid="toc-sticky-element"
        style={{ 
          position: 'sticky',
          top: '100px'
        }}
      >
        <div className={styles.tocSearch}>
          <div className={styles.searchInputWrapper}>
            <Search size={14} className={`${styles.searchIcon} ${isDarkMode ? styles.darkMode : ''}`} />
            <input
              ref={searchInputRef}
              type="text"
              placeholder="Search changes..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`${styles.tocSearchInput} ${isDarkMode ? styles.darkMode : ''}`}
              onKeyDown={(e) => {
                // Prevent typing '/' in the search input
                if (e.key === '/' && e.target.value === '') {
                  e.preventDefault();
                }
              }}
            />
            {!searchTerm && (
              
              <div 
                className={`${styles.keyboardShortcutContainer} ${isDarkMode ? styles.darkMode : ''}`}
                onClick={() => searchInputRef.current?.focus()}
                title="Press / to search"
              >
                {/* Replace with SVG once it's created in Figma */}
                <img 
                  src={SlashKeySVG} 
                  alt="Press / to search" 
                  className={styles.slashKeySVG} 
                />
               
                {/* Temporary placeholder until SVG is ready */}
                {/* <span className={styles.slashKeyPlaceholder}>/</span> */}
              </div>
            )}
            {searchTerm && (
              <button 
                className={`${styles.clearSearchButton} ${isDarkMode ? styles.darkMode : ''}`}
                onClick={clearSearch}
                aria-label="Clear search"
              >
                <X size={14} />
              </button>
            )}
          </div>
        </div>

        {filteredChanges.length === 0 ? (
          <div className={`${styles.noResults} ${isDarkMode ? styles.darkMode : ''}`}>No matching changes found</div>
        ) : viewMode === 'flat' ? (
          <List
            height={Math.min(400, flattenedItems.length * 55)}
            itemCount={flattenedItems.length}
            itemSize={55}
            width="100%"
            className={`${styles.virtualizedList} ${isDarkMode ? styles.darkMode : ''}`}
          >
            {Row}
          </List>
        ) : (
          <ul className={styles.tocList}>
            {Object.entries(groupedChanges)
              .sort(([yearA], [yearB]) => parseInt(yearB) - parseInt(yearA))
              .map(([year, yearChanges]) => (
              <li key={year} className={styles.tocYearGroup}>
                <div 
                  className={`${styles.tocYearHeader} ${isDarkMode ? styles.darkMode : ''}`}
                  onClick={() => toggleGroup(year)}
                >
                  <span className={`${styles.tocYear} ${isDarkMode ? styles.darkMode : ''}`}>{year}</span>
                  {expandedGroups[year] ? 
                    <ChevronUp size={16} /> : 
                    <ChevronDown size={16} />
                  }
                </div>
                
                {expandedGroups[year] && (
                  <ul className={`${styles.tocYearItems} ${isDarkMode ? styles.darkMode : ''}`}>
                    {yearChanges.map((change) => (
                                              <div className={styles.tocItemContent}>

                      <li 
                        key={change.originalIndex} 
                        className={`${styles.tocItem} ${activeSection === change.originalIndex ? styles.tocItemActive : ''} ${isDarkMode ? styles.darkMode : ''}`}
                        onClick={() => scrollToSection(change.originalIndex)}
                      >
                          <div className={styles.tocItemWrapper}>
                            <div className={styles.tocVersionWrapper}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                              <span className={`${styles.tocVersion} ${isDarkMode ? styles.darkMode : ''}`}>{change.version}</span>
                              <span className={`${styles.tocItemTitle} ${isDarkMode ? styles.darkMode : ''}`}>{change.title}</span>
                            </div>
                            <button 
                              className={styles.tocCopyLinkButton}
                              onClick={(e) => copyVersionLink(change.version, e)}
                              title="Copy link to this version"
                            >
                              <Link2 size={12} />
                              {copiedVersion === change.version && (
                                <span className={styles.tocCopiedTooltip}>Copied</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </li>
                      </div>

                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
  );
};

export default TableOfContents; 