import React, { useState, useEffect } from 'react';
import styles from './word_switch.module.css'; // Import the CSS file for styling

const WordSwitch = () => {
    const allWords = [
        'Paris', 'London', 'Tokyo', 'New York', 'Sydney', 'Rome', 'Dubai', 'Singapore', 'Berlin', 'Madrid',
        'Venice', 'Amsterdam', 'Barcelona', 'Seoul', 'Bangkok', 'Istanbul', 'Vienna', 'Prague', 'San Francisco', 'Copenhagen',
        'Stockholm', 'Oslo', 'Moscow', 'Mumbai', 'Cairo', 'Athens', 'Dublin', 'Lisbon', 
        'Milan', 'Munich', 'Vancouver', 'Toronto', 'Montreal', 'Rio de Janeiro', 'Buenos Aires', 'Cape Town',
        'Marrakech', 'Kyoto', 'Shanghai', 'Hong Kong', 'Melbourne', 'Edinburgh', 'Florence', 'Geneva',
        'Zurich', 'Helsinki', 'Warsaw', 'Budapest', 'Brussels', 'Kuala Lumpur', 'Jakarta', 'Lima', 'Santiago', 'Bogota',
        'Mexico City', 'Lagos', 'Nairobi', 'Casablanca', 'Doha', 'Manila', 'Hanoi', 'Kolkata', 'Karachi', 'Lahore',
        'Tehran', 'Riyadh', 'Jeddah', 'Abu Dhabi', 'Auckland', 'Wellington', 'Perth', 'Brisbane', 'Adelaide', 'Christchurch',
        'Honolulu', 'Las Vegas', 'Miami', 'Boston', 'Chicago', 'Los Angeles', 'Seattle', 'Dallas', 'Houston', 'Atlanta',
        'Philadelphia', 'San Diego', 'Phoenix', 'San Antonio', 'San Jose', 'Detroit', 'Minneapolis', 'Denver', 'Portland'
    ];

    const getRandomWord = () => allWords[Math.floor(Math.random() * allWords.length)];

    const [currentWord, setCurrentWord] = useState(getRandomWord());
    const [isBlurred, setIsBlurred] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsBlurred(true);
            setTimeout(() => {
                const randomIndex = Math.floor(Math.random() * allWords.length);
                setCurrentWord(allWords[randomIndex]);
            }, 500 + Math.random() * 200); // Add randomness to the blur peak timing

            setTimeout(() => {
                setIsBlurred(false);
            }, 1000 + Math.random() * 200); // Add randomness to the blur end timing
        }, 1000 + Math.random() * 700); // Add randomness to the interval timing

        return () => clearInterval(interval);
    }, [allWords]);

    return (
        <div style={{textAlign: 'center'}} className={isBlurred ? styles.blurred : ''}>
            <p className={styles.word}>{currentWord}</p>
        </div>
    );
};

export default WordSwitch;