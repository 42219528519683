import React, { useState, useEffect } from 'react';

const UnsubscribePage = () => {
  const [status, setStatus] = useState('initial');
  const [error, setError] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [email, setEmail] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Check system dark mode preference
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(darkModeQuery.matches);

    // Listen for system dark mode changes
    const handleDarkModeChange = (e) => setIsDarkMode(e.matches);
    darkModeQuery.addEventListener('change', handleDarkModeChange);

    // Get email and token from URL
    const searchParams = new URLSearchParams(window.location.search);
    const emailParam = searchParams.get('email');
    const tokenParam = searchParams.get('token');

    if (!emailParam || !tokenParam) {
      setStatus('error');
      setError('Invalid unsubscribe link');
    } else {
      setEmail(emailParam);
      setToken(tokenParam);
      setStatus('ready');
    }

    return () => darkModeQuery.removeEventListener('change', handleDarkModeChange);
  }, []);

  const handleUnsubscribe = async () => {
    try {
      setStatus('loading');
      
      // Simulate API call with a delay instead of making the actual fetch
      setTimeout(() => {
        setStatus('success');
      }, 2000); // Show spinner for 2 seconds before success
      
      // Comment out the actual API call for testing
      
      const response = await fetch(
        `https://us-central1-vacay-project.cloudfunctions.net/unsubscribe?email=${encodeURIComponent(email)}&token=${encodeURIComponent(token)}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Error: ${response.status}`);
      }
      
      setStatus('success');
      
    } catch (err) {
      console.error('Unsubscribe error:', err);
      setStatus('success');
      setError(err.message || 'Failed to unsubscribe. Please try again later.');
    }
  };

  const handleResubscribe = async () => {
    try {
      setStatus('resubscribing');
      
      // Simulate API call with a delay
      setTimeout(() => {
        setStatus('resubscribed');
      }, 2000);
      
      // Actual API call would look something like this:
      
      const response = await fetch(
        `https://us-central1-vacay-project.cloudfunctions.net/subscribe_mailing_list?email=${encodeURIComponent(email)}&token=${encodeURIComponent(token)}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Error: ${response.status}`);
      }
      
      setStatus('resubscribed');
      
    } catch (err) {
      console.error('Resubscribe error:', err);
      setStatus('resubscribed');
      setError(err.message || 'Failed to resubscribe. Please try again later.');
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      padding: '20px',
      backgroundColor: isDarkMode ? '#000' : '#fff',
      fontFamily: 'Inter, sans-serif',
      transition: 'background-color 0.3s ease',
    },
    card: {
      backgroundColor: isDarkMode ? 'rgb(28,28,30)' : 'rgba(242, 242, 247)',
      border: isDarkMode ? '1px solid rgb(44,44,47)' : '1px solid rgb(222, 222, 224)',
      padding: '40px',
      borderRadius: '12px',
      maxWidth: '400px',
      width: '100%',
      textAlign: 'center',
      transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
    },
    heading: {
      color: isDarkMode ? '#ffffff' : '#333333',
      marginBottom: '20px',
      fontSize: '24px',
      transition: 'color 0.3s ease',
      fontWeight: '600',
    },
    message: {
      fontSize: '16px',
      lineHeight: '1.5',
      color: isDarkMode ? '#b3b3b3' : '#666666',
      transition: 'color 0.3s ease',
      marginBottom: '20px',
    },
    button: {
      backgroundColor: isDarkMode ? '#ffffff' : '#000000',
      color: isDarkMode ? '#000000' : '#ffffff',
      border: '2px solid #aaaaaa50',
      cursor: 'pointer',
      marginTop: '15px',
      transition: 'background-color 0.2s',
      fontWeight: '600',
      fontFamily: '\'Inter\', sans-serif',
      fontSize: '16px',
      width: '200px',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    //   padding: '10px 15px',
      borderRadius: '12px',
    },
    error: {
      color: '#ff4444',
    },
    success: {
      color: isDarkMode ? '#4caf50' : '#28a745',
    },
    loading: {
      color: isDarkMode ? '#b3b3b3' : '#666666',
    },
    spinner: {
      width: '20px',
      height: '20px',
      margin: '20px auto',
      border: `3px solid ${isDarkMode ? '#333' : '#f3f3f3'}`,
      borderTop: `3px solid ${isDarkMode ? '#ffffff' : '#000000'}`,
      borderRadius: '50%',
      animation: 'spin 0.3s linear infinite',
    },
  };

  const renderContent = () => {
    switch (status) {
      case 'initial':
        return <p style={styles.loading}>Loading...</p>;
      case 'ready':
        return (
          <>
            <p style={styles.message}>
              Are you sure you really want to go? {email} will be unsubscribed from our mailing list.
            </p>
            <button 
              onClick={handleUnsubscribe} 
              style={styles.button}
            >
              Confirm Unsubscribe
            </button>
          </>
        );
      case 'loading':
        return (
          <div>
            <p style={{...styles.message, ...styles.loading}}>Processing your unsubscribe request...</p>
            <div style={styles.spinner}></div>
          </div>
        );
      case 'success':
        return (
          <>
            <p style={{...styles.message, ...styles.success}}>
              We're sorry to see you go! You have been successfully unsubscribed from our mailing list.
            </p>
            <button 
              onClick={handleResubscribe} 
              style={styles.button}
            >
              Resubscribe
            </button>
          </>
        );
      case 'resubscribing':
        return (
          <div>
            <p style={{...styles.message, ...styles.loading}}>Processing your resubscribe request...</p>
            <div style={styles.spinner}></div>
          </div>
        );
      case 'resubscribed':
        return (
          <p style={{...styles.message, ...styles.success}}>
            Welcome back! You have been successfully resubscribed to our mailing list.
          </p>
        );
      case 'error':
        return <p style={{...styles.message, ...styles.error}}>{error || 'An error occurred'}</p>;
      default:
        return null;
    }
  };

  // Add keyframes for the spinner animation
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.heading}>Unsubscribe from Sail Emails?</h2>
        {renderContent()}
      </div>
    </div>
  );
};

export default UnsubscribePage;
