import React from 'react';
import Header from './header';
import Footer from '../components/footer';


const PrivacyPolicy = () => {

  return (
        <div>
        <Header />
        <div className='privacy-policy' style={{textAlign:"left", padding:"20px", maxWidth:"600px", margin: "auto"}}>
        <h1 type="title" style={{fontSize:"50px", textAlign:"center", lineHeight: '75px'}}>Privacy Policy</h1>
          <b>Effective Date: 03/08/2025</b>
          <br />
          <br />
          
          <b>1. Introduction</b><br />
          <br />
          Welcome to Sail. Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you visit our website [sailvacations.app] ("Website") or use our mobile application ("App"). By using our Website or App, you agree to the practices described in this policy.
          <br />
          <br />
          
          <b>2. Information We Collect</b><br />
          <br />
          We collect information in two ways: <br /> <br />
          <b>Information You Provide:</b> When you create an account, submit inquiries, or interact with our services, we may collect your name, email address, profile details, travel itineraries, and other information you provide. <br /> <br />
          <b>Automatically Collected Information:</b> We may collect non-personal data such as your IP address, device type, browser type, operating system, referring URLs, and usage activity through cookies and analytics tools. <br /> <br />
          <b>Location Data:</b> If you grant permission, we may collect your location data to enhance travel planning and provide location-based recommendations.
          <br />
          <br />
          
          <b>3. How We Use Your Information</b><br />
          <br />
          We use your information to:
          <ul style={{paddingLeft: "20px"}}>
            <li>Provide, operate, and improve our Website, App, and services.</li>
            <li>Enable travel itinerary creation, sharing, and collaboration.</li>
            <li>Personalize your experience and provide relevant recommendations.</li>
            <li>Respond to inquiries and communicate with you.</li>
            <li>Analyze user engagement, trends, and app performance.</li>
            <li>Enforce our terms and protect against fraud.</li>
          </ul>
          <br />
          <br />
          
          <b>4. Cookies & Tracking Technologies</b><br />
          <br />
          We use cookies and similar technologies to enhance user experience and analyze Website and App performance. You can manage cookie preferences in your browser settings.
          <br />
          <br />
          
          <b>5. Third-Party Services</b><br />
          <br />
          We may use third-party analytics, payment processors, and service providers (such as Google Analytics, Firebase, and Stripe) to operate our Website and App. These providers may collect information about your activity through cookies and similar technologies.
          <br />
          <br />
          
          <b>6. Data Sharing & Disclosure</b><br />
          <br />
          We do not sell or rent your personal data. However, we may share information:
          <ul style={{paddingLeft: "20px"}}>
            <li>With service providers who assist in Website and App operation.</li>
            <li>With other users, if you choose to share travel itineraries or collaborate on trips.</li>
            <li>If required by law or to protect our rights.</li>
            <li>In connection with a business transfer (e.g., merger, sale of assets).</li>
          </ul>
          <br />
          <br />
          
          <b>7. Data Security</b><br />
          <br />
          We take reasonable measures to protect your information, but no method of transmission over the internet is 100% secure. Use our Website and App at your own risk.
          <br />
          <br />
          
          <b>8. Your Rights & Choices</b><br />
          <br />
          You have the right to:
          <ul style={{paddingLeft: "20px"}}>
            <li>Request access to or deletion of your personal data.</li>
            <li>Opt out of marketing communications.</li>
            <li>Manage cookies and location permissions via browser and device settings.</li>
            <li>Delete your account via in-app settings or by contacting us.</li>
          </ul>
          <br />
          <br />
          
          <b>9. Third-Party Links</b><br />
          <br />
          Our Website and App may contain links to external sites. We are not responsible for the privacy practices of third-party websites or services.
          <br />
          <br />
          
          <b>10. Changes to This Policy</b><br />
          <br />
          We may update this Privacy Policy periodically. The latest version will always be available on our Website and within the App.
          <br />
          <br />
          
          <b>11. Contact Us</b><br />
          <br />
          If you have any questions about this Privacy Policy, contact us at: support@sailvacations.app.
          <br />
          <br />
        </div>
        <Footer />
        </div>
  );
};

export default PrivacyPolicy;