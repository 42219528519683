import React, { useContext, useState, useEffect } from 'react';
import styles from './cta.module.css';
import { ArrowRight } from 'lucide-react';
import { DarkModeContext } from '../DarkModeProvider';
import { Link } from 'react-router-dom';


export default function CTA() {
    const { isDarkMode } = useContext(DarkModeContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', textAlign: 'center', zIndex: 3, marginTop: "50px", gap: "10px"}}>
                        
                        <Link to="/coming-soon" style={{ textDecoration: 'none' }}>
                            <div className={styles.ctaButton2} style={{
                            backgroundColor: isDarkMode ? "rgb(28, 28, 30)" : "white", 
                            boxShadow: isDarkMode ? 'rgba(45, 35, 66, 0.4) 0 2px 4px, #00000070 0 7px 13px -3px, #00000040 0 -3px 3px inset' : 'rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 3px inset, #ffffff90 0 3px 2px inset',
                            cursor: 'pointer'
                            }}>
                                <p style={{
                                fontWeight: "500", 
                                margin: 0, 
                                color: isDarkMode ? "white" :  'black',
                                fontSize: !isMobile ? "12pt" : "8pt"}}>
                                    Android and Web</p>
                                {/* <p style={{ 
                                color: '#3b3116', 
                                position: 'absolute', 
                                bottom: '-30px', 
                                backgroundColor: '#fff1c790',
                                border: '1px solid #FFC921', 
                                borderRadius: '25px', 
                                padding: '0px 7px', 
                                fontSize:"8pt",
                                backdropFilter: 'blur(10px)' 
                                }}><b>COMING SOON</b></p> */}
                            </div>
                        </Link>
                        <a className={styles.ctaButton} style={{

backgroundColor: !isDarkMode ? "#1a1a1a" : "#FCFCFD",
boxShadow: !isDarkMode ? 'rgba(45, 35, 66, 0.4) 0 2px 4px, #00000070 0 7px 13px -3px, #00000040 0 -3px 0px inset, #ffffff50 0 3px 3px inset, #ffffff50 0 -2px 3px inset' : 'rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 3px inset',
color: !isDarkMode ? "white" : '#36395A',

}} href="https://apps.apple.com/us/app/sail-vacations-social-travel/id6471536080?ign-itscg=30200&ign-itsct=apps_box_link&mttnsubad=6471536080">
<p style={{fontWeight: "500", color: isDarkMode ? "black" :  'white', fontSize: !isMobile ? "12pt" : "8pt"}}>Get Sail on iOS </p><ArrowRight/>
</a>
                    </div>
        </div>
    );
}   