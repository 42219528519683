import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo_black.svg';
import logo_white from '../assets/logo_white.svg';
import { DarkModeContext } from '../DarkModeProvider';


const Header = () => {
  const { isDarkMode } = React.useContext(DarkModeContext);
  
  return (
    <>
      <div className="header-container" style={{position: "fixed", width: "100%", top: 0, zIndex: 2000}}>
        <div className="header">
          <Link to="/" style={{textDecoration: "none", color: "black", display: "flex", flexDirection: "row",  margin: "0", padding: "0", width: "150px"}}>
            <div style={{marginLeft: "10px",marginTop: "7px", display: "flex", alignContent:"center", width: "100px" , justifyContent: "left"}}>
              <img src={isDarkMode ? logo_white : logo } alt="Logo" style={{height: "30px", margin: "0", padding: "0"}} />
              <h1 style={{fontFamily: "Inter", fontWeight: "600", height: "30px", fontSize: "25px", margin: "0", padding: "0", lineHeight:"30px"}}>SAIL</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className='header-background' style={{borderBottom: isDarkMode ? "3px solid rgba(0, 0, 0, 1)" :  '3px solid rgba(255, 255, 255, 1)', position: "fixed", height: "50px", width: "100%", backdropFilter: "blur(30px)", top: 0, zIndex: 1999}}>
      </div>
    </>
  );
};

export default Header;